import { createSlice } from "@reduxjs/toolkit";

export const estimations = createSlice({
  name: "estimations",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init estimations
     * @param {ReduxState} state
     * @return {void}
     */
    initEstimations: (state) => {
      state.init = true;
    },
    /**
     * Deinit estimations
     * @param {ReduxState} state
     * @return {void}
     */
    deinitEstimations: (state) => {
      state.init = false;
    },
    /**
     *  Set estimations
     * @param {ReduxState} state
     * @param {Action} action
     */
    setEstimations: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initEstimations,
  deinitEstimations,
  setEstimations,
} = estimations.actions;

export default estimations.reducer;
