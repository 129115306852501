import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import * as translations from "./translations/en.json";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

import "./App.scss";
import { LogoAnimStart } from "./components/LogoAnimStart";
import version from "./version.json";
import PrivacyPolicy from "./views/PrivacyPolicy";

const App = React.lazy(() => import("./App"));
const ProtectedRoute = React.lazy(() => import("./views/RouteTypes/ProtectedRoute"));
const PublicRoute = React.lazy(() => import("./views/RouteTypes/PublicRoute"));
const NotFound = React.lazy(() => import("./views/NotFound"));
const Login = React.lazy(() => import("./views/Login"));
const CreateAccount = React.lazy(() => import("./views/CreateAccount"));
const ResetPassword = React.lazy(() => import("./views/ResetPassword"));
const AuthAction = React.lazy(() => import("./views/AuthAction"));
const Settings = React.lazy(() => import("./views/Settings/settings"));
const GeneralSettings = React.lazy(() => import("./views/Settings/General"));
const MyTasks = React.lazy(() => import("./views/MyTasks"));
const Projects = React.lazy(() => import("./views/Projects"));
const ProjectDetail = React.lazy(() => import("./views/Projects/ProjectDetail"));
const Planning = React.lazy(() => import("./views/Planning"));
const TaskDrawer = React.lazy(() => import("./views/TaskDrawer"));
const Workload = React.lazy(() => import("./views/Workload"));
const Calendar = React.lazy(() => import("./views/Calendar"));
const Prioritize = React.lazy(() => import("./views/Prioritize"));
const Packages = React.lazy(() => import("./views/Packages"));
const PackageDetail = React.lazy(() => import("./views/Packages/PackageDetail"));
const AccountSettings = React.lazy(() => import("./views/Settings/Account"));
const Account = React.lazy(() => import("./views/Settings/Account"));
const Resources = React.lazy(() => import("./views/Settings/Resources"));
const Skills = React.lazy(() => import("./views/Settings/Skills"));
const Tags = React.lazy(() => import("./views/Settings/Tags"));
const Financials = React.lazy(() => import("./views/Settings/Financials"));
const Integrations = React.lazy(() => import("./views/Settings/Integrations"));
const Permissions = React.lazy(() => import("./views/Settings/Permissions"));
const Meetings = React.lazy(() => import("./views/Meetings"));
const Expenses = React.lazy(() => import("./views/Expenses"));
const EditResource = React.lazy(() => import(
  "./views/Settings/Resources/editResource/editResource"
));
const DetailsIntegrationSettings = React.lazy(() => import(
  "./views/Settings/Integrations/Details"
));
const Timeoff = React.lazy(() => import("./views/Settings/Timeoff"));

Bugsnag.start({
  apiKey: "eeb9fa31136492da4daa5cf54c7eb058",
  plugins: [new BugsnagPluginReact()],
  appVersion: version.lastAt,
  // eslint-disable-next-line jsdoc/require-jsdoc
  onError: (event) => {
    event.setUser(
      store.getState()?.user?._id || undefined,
      store.getState()?.user?.email || undefined,
    );
  },
  logger: null,
});
BugsnagPerformance.start({ apiKey: "eeb9fa31136492da4daa5cf54c7eb058" as string });



const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <ProtectedRoute><MyTasks /></ProtectedRoute>,
      },
      {
        path: "projects",
        element: <ProtectedRoute><Projects /></ProtectedRoute>,
      },
      {
        path: "projects/:id",
        element: <ProtectedRoute><ProjectDetail /></ProtectedRoute>,
      },
      {
        path: "planning",
        element: <ProtectedRoute><Planning /></ProtectedRoute>,
        children: [
          {
            path: ":id",
            element: <ProtectedRoute><TaskDrawer /></ProtectedRoute>,
          },
        ],
      },
      {
        path: "workload",
        element: <ProtectedRoute><Workload /></ProtectedRoute>,
      },
      {
        path: "calendar",
        element: <ProtectedRoute><Calendar /></ProtectedRoute>,
      },
      {
        path: "prioritize",
        element: <ProtectedRoute><Prioritize /></ProtectedRoute>,
      },
      {
        path: "packages",
        element: <ProtectedRoute><Packages /></ProtectedRoute>,
      },
      {
        path: "packages/:id",
        element: <ProtectedRoute><PackageDetail /></ProtectedRoute>,
      },
      {
        path: "meetings",
        element: <ProtectedRoute><Meetings /></ProtectedRoute>,
      },
      {
        path: "expenses",
        element: <ProtectedRoute><Expenses /></ProtectedRoute>,
      },
      {
        path: "settings",
        element: <ProtectedRoute><Settings /></ProtectedRoute>,
        children: [
          {
            index: true,
            element: <GeneralSettings />,
          },
          {
            path: "account",
            element: <AccountSettings />,
          },
          {
            path: "account",
            element: <Account />,
          },
          {
            path: "resources",
            element: <Resources />,
            children: [
              {
                path: ":id",
                element: <EditResource />,
              },
            ],
          },
          {
            path: "skills",
            element: <Skills />,
          },
          {
            path: "timeoffs",
            element: <Timeoff />,
          },
          {
            path: "tags",
            element: <Tags />,
          },
          {
            path: "financials",
            element: <Financials />,
          },
          {
            path: "permissions",
            element: <Permissions />,
          },
          {
            path: "integrations",
            element: <Integrations />,
          },
          {
            path: "integrations/:id",
            element: <DetailsIntegrationSettings />,
          },
        ],
      },
      {
        path: "createAccount",
        element: <ProtectedRoute><CreateAccount /></ProtectedRoute>,
      },
      {
        path: "login",
        element: <PublicRoute><Login /></PublicRoute>,
        children: [
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: "__/auth/action",
        element: <AuthAction />,
      },
      {
        path: "oauth2/redirect/google",
        element: <AuthAction />,
      },
      {
        path: "privacy",
        element: <PrivacyPolicy />,
      },
    ],
  },
]);

const rootEl:Element = document?.getElementById("root") || new Element();
const root = ReactDOM.createRoot(rootEl);
root.render(ErrorBoundary ?
  <ErrorBoundary>
    <Provider store={store}>
      <IntlProvider messages={translations} locale="en" defaultLocale="en">
        <Suspense fallback={<LogoAnimStart />}>
          <RouterProvider router={router} />
        </Suspense>
      </IntlProvider>
    </Provider>
  </ErrorBoundary> :
  <Provider store={store}>
    <IntlProvider messages={translations} locale="en" defaultLocale="en">
      <Suspense fallback={<LogoAnimStart />}>
        <RouterProvider router={router} />
      </Suspense>
    </IntlProvider>
  </Provider>
);
